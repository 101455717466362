<template>
  <div class="courses">
    <home-header></home-header>
    <div class="contentWrap">
      <div class="unitsWrap" v-loading="loading">
        <img v-if="anchorShow" class="anchor" @click="anchorHandle" src="@/assets/images/courses/unit/anchor.png" alt="anchor">
        <template v-for="(unit, i) in unitsList">
          <!-- testout -->
          <template v-if="unit.lsNm[transLanguage].indexOf('TESTOUT') >= 0">
            <div class="testout" :class="{lock: !!unit.lock}" @click="startLessonHandle(unit.ls[0], 1, unit.lsID, unit.free, $event)" :key="i">{{$t('course.test_out')}}</div>
          </template>

          <!-- normal -->
          <template v-else>
            <div class="unitWrap" :class="[`unit_${unit.lsIndex}`, `colorGroup_${unit.lsIndex % 3 === 0 ? 3 : unit.lsIndex % 3}`, {lock: !!unit.lock}]" :key="i">
              <div class="unitHeader">
                <div class="unitNameWrap">
                  <div class="unitIndex">{{unit.lsIndex}}</div>
                  <div class="unitName">{{unit.lsNm[transLanguage]}}</div>
                </div>
                <div class="icon grammarTipsIcon" @click="startGrammarTipsHandle(unit, $event)"></div>
              </div>
              <template v-for="(lesson, j) in unit.ls">
                <!-- lesson -->
                <template v-if="lesson.type === 'normal'">
                  <div class="lesson" :class="{lock: !!lesson.lock }" @click="startLessonHandle(lesson, j, unit.lsID, unit.free || lesson.free, $event)" :key="j + '_n'">
                    <div v-if="!isMember && !unit.free && !lesson.free" class="crownIconWrap"><div class="crownIcon"></div></div>
                    <img v-if="lesson.bookmark" class="bookmark" src="@/assets/images/courses/unit/bookmark.png" alt="bookmark">
                    <div class="icon lessonIcon"></div>
                    <div class="lessonTextWrap">
                      <div class="lessonIndexText">{{$t('course.lesson_s').replace('%s', j + 1)}}</div>
                      <div class="lessonDsText">{{lesson.ds}}</div>
                    </div>
                    <div class="icon" :class="[lesson.lock ? 'lockIcon' : lesson.redo ? 'redoIcon' : 'startIcon']"></div>
                  </div>
                </template>
                <!-- dialoguePractice -->
                <template v-else-if="lesson.type === 'dialoguePractice'">
                  <div class="lesson" :class="{lock: !!lesson.lock }" @click="startLessonHandle(lesson, j, unit.lsID, unit.free || lesson.free, $event)" :key="j + '_d'">
                    <div v-if="!isMember && !unit.free && !lesson.free" class="crownIconWrap"><div class="crownIcon"></div></div>
                    <img v-if="lesson.bookmark" class="bookmark" src="@/assets/images/courses/unit/bookmark.png" alt="bookmark">
                    <div class="icon dialoguePracticeIcon"></div>
                    <div class="lessonTextWrap">
                      <div class="lessonIndexText">{{$t('course.comprehension')}}</div>
                    </div>
                    <div class="icon" :class="[lesson.lock ? 'lockIcon' : lesson.redo ? 'redoIcon' : 'startIcon']"></div>
                  </div>
                </template>
                <!-- dialogueSpeaking -->
                <template v-else-if="lesson.type === 'dialogueSpeaking'">
                  <div class="lesson" :class="{lock: !!lesson.lock }" @click="startLessonHandle(lesson, j, unit.lsID, unit.free || lesson.free, $event)" :key="j + '_s'">
                    <div v-if="!isMember && !unit.free && !lesson.free" class="crownIconWrap"><div class="crownIcon"></div></div>
                    <img v-if="lesson.bookmark" class="bookmark" src="@/assets/images/courses/unit/bookmark.png" alt="bookmark">
                    <div class="icon dialogueSpeakingIcon"></div>
                    <div class="lessonTextWrap">
                      <div class="lessonIndexText">{{$t('course.speaking')}}</div>
                    </div>
                    <div class="icon" :class="[lesson.lock ? 'lockIcon' : lesson.redo ? 'redoIcon' : 'startIcon']"></div>
                  </div>
                </template>
              </template>
            </div>
          </template>

          <!-- 购买链接 -->
          <template v-if="!isMember && unit.lsIndex === 1">
            <router-link to="/pricing" class="joinPremium" :key="i + '_premium'">
              <img src="@/assets/images/courses/unit/crown.png" alt="crown">
              <div class="title">{{$t('buy.unlock_all_lessons')}}</div>
              <div class="subtitle">{{$t('buy.enjoy_FluenDay_to_the_fullest')}}</div>
              <div class="buyBtn">{{$t('buy.join_premium')}}</div>
            </router-link>
          </template>
        </template>
      </div>

      <div class="mask" v-show="maskShow" @click="maskShow=false">
        <div class="dialogueSpeakingWindow" @click.stop>
          <img @click="maskShow=false" class="closeImg" src="@/assets/images/clips/lesson/close.png" alt="close">
          <div class="windowContent">
            <div class="followBtn" @click="startSpeakingHandle('follow')"><img src="@/assets/images/courses/unit/follow.png" alt="follow">{{$t('course.warn_up')}}</div>
            <div class="roleplayBtn" @click="startSpeakingHandle('roleplay')"><img src="@/assets/images/courses/unit/roleplay.png" alt="roleplay">{{$t('course.role_play')}}</div>
          </div>
        </div>
      </div>

      <sidebar>
        <div class="sidebarItem alphabet" @click="startAlphabetHandle">
          <img class="itemIcon" src="@/assets/images/sidebar/alphabet.png" alt="alphabetIcon">
          <div class="itemText">{{$t('course.alphabet')}}</div>
        </div>
      </sidebar>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import Header from '@/components/Header'
  import Sidebar from '@/components/Sidebar'
  import constants from '@/utils/constants'
  import { getUnitsData, getGrammarTipsData } from '@/api/courses'

  export default {
    data() {
      return {
        unitsData: [],
        maskShow: false,
        anchorShow: false,
        loading: false
      }
    },
    components: {
      'home-header': Header,
      Sidebar
    },
    computed: {
      ...Vuex.mapGetters(['testSettings', 'progressData', 'coursesLessonInfo', 'userInfo', 'userLanguage', 'learnLanguage', 'isMember']),
      transLanguage() {
        return this.userLanguage === 'cn' ? 'tch' : this.userLanguage
      },
      unitsList() {
        let unitsList = []
        if (this.unitsData.length) {
          let langParam = `${this.learnLanguage}pal`  // enpal / espal / frpal...
          let main = this.progressData?.[langParam]?.m_course?.main
          let main_tt = this.progressData?.[langParam]?.m_course?.main_tt
          let extra_progress = this.progressData?.[langParam]?.m_course?.extra_progress
          let mainUnitIndex = 1
          let mainLessonIndex = 1
          let main_ttData = {}
          let extraData = {}
          let testoutLockFlag = 1
  
          if (main) {
            mainUnitIndex = Number(main.split(':')[1])
            mainLessonIndex = Number(main.split(':')[2])
          }

          if (main_tt) {
            let main_ttArr = main_tt.split(';').filter(item => item !== '')
            for (let main_ttItem of main_ttArr) {
              let unitID = main_ttItem.split(':')[0]
              let lessonIndex = Number(main_ttItem.split(':')[1])
              main_ttData[unitID] = lessonIndex
            }
          }

          if (extra_progress) {
            let extra_progressArr = extra_progress.split(';').filter(item => item !== '')
            for (let extra_progressItem of extra_progressArr) {
              let unitID = extra_progressItem.split(':')[0]
              extraData[unitID] = extra_progressItem.split(':')[1] // 0_1_1 warmup_practice_speaking
            }
          }

          for (let i = 0; i < this.unitsData.length; i ++) {
            let unit = this.unitsData[i]
            let unitID = unit.lsID

            // 1. 比对main
            if (mainUnitIndex > unit.lsIndex) {
              // 如果单元index小于main的单元进度，则该单元和全部课程解锁
              unit.lock = 0
              unit.ls.forEach(lesson => {
                lesson.lock = 0
                if (lesson.type === 'normal') lesson.redo = 1
              })
            } else if (mainUnitIndex === unit.lsIndex) {
              // 如果单元index等于main的单元进度，则该单元解锁，再根据main的课程进度判断课程是否解锁
              unit.lock = 0
              for (let j = 0; j < unit.ls.length; j ++) {
                let lesson = unit.ls[j]
                let lessonIndex = j + 1
  
                if (mainLessonIndex > lessonIndex) {
                  lesson.lock = 0
                  lesson.redo = 1
                } else if (mainLessonIndex === lessonIndex) {
                  lesson.lock = 0
                } else {
                  lesson.lock = 1
                }

                // 判断对话课是否解锁
                if (lesson.type === 'dialogueSpeaking') {
                  let dialoguePractice = unit.ls[j - 1]
                  lesson.lock = dialoguePractice.lock
                }
              }
            } else {
              // 如果单元index大于main的单元进度，则该单元和全部课程锁定
              unit.lock = 1
              unit.ls.forEach(lesson => lesson.lock = 1)
            }

            // 2. 比对main_tt
            if (main_ttData[unitID]) {
              // 如果main_tt中有该单元，则该单元解锁，再根据main_tt的课程进度判断课程是否解锁
              unit.lock = 0
              let main_ttLessonIndex = main_ttData[unitID]
              for (let k = 0; k < unit.ls.length; k ++) {
                let lesson = unit.ls[k]
                let lessonIndex = k + 1
  
                if (main_ttLessonIndex > lessonIndex) {
                  lesson.lock = 0
                  lesson.redo = 1
                } else if (main_ttLessonIndex === lessonIndex) {
                  lesson.lock = 0
                }

                // 判断对话课是否解锁
                if (lesson.type === 'dialogueSpeaking') {
                  let dialoguePractice = unit.ls[k - 1]
                  lesson.lock = dialoguePractice.lock
                }
              }
            }

            // 3. 比对extra
            if (extraData[unitID]) {
              let extraVal = extraData[unitID]
              for (let l = 0; l < unit.ls.length; l ++) {
                let lesson = unit.ls[l]
  
                if (lesson.type === 'dialoguePractice') {
                  lesson.redo = Number(extraVal.split('_')[1])
                } else if (lesson.type === 'dialogueSpeaking') {
                  lesson.redo = Number(extraVal.split('_')[2])
                }
              }
            }

            // 4. 判断testout是否解锁：该testout前上一个testout后有单元解锁则该testout解锁
            if (!unit.lock) testoutLockFlag = 0
            if (!unit.lsIndex) {
              unit.lock = testoutLockFlag
              unit.ls[0].lock = testoutLockFlag
              testoutLockFlag = 1
            }

            unitsList.push(unit)
          }
        }

        return unitsList
      }
    },
    created() {
      this.init()
    },
    mounted() {
      window.addEventListener('scroll', this.isInViewPort)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.isInViewPort)
    },
    watch: {
      userLanguage() {
        this.init()
      },
      learnLanguage() {
        this.init()
      },
      unitsList(val) {
        if (val.length) {
          this.$nextTick(() => {
            this.isInViewPort()
          })
        }
      }
    },
    methods: {
      async init() {
        this.$store.commit('set_learnLanguage', 'en')
        
        this.loading = true
        let transList = constants.langsData[this.learnLanguage]['courseTrans']
        if (transList.includes(this.userLanguage)) {
          let currentCoursesLesson = localStorage.getItem('currentCoursesLesson') || '1_normal'
          let unitsData = await getUnitsData(this.learnLanguage)
          let index = 1

          for (let i = 0; i < unitsData.length; i ++) {
            let unit = unitsData[i]
            let lastUnit = unitsData[i - 1]
            if (unit.lsNm[this.transLanguage].indexOf('TESTOUT') === -1) {
              for (let i = 0; i < unit.ls.length; i ++) {
                let lesson = unit.ls[i]
                if (!lesson.ds && (i === unit.ls.length - 1)) {
                  // 添加对话练习
                  lesson.type = 'dialoguePractice'
                  // 添加对话口语
                  let lessonCopy = JSON.parse(JSON.stringify(lesson))
                  lessonCopy.type = 'dialogueSpeaking'

                  if (`${lesson.id}_${lesson.type}` === currentCoursesLesson) lesson.bookmark = true
                  if (`${lessonCopy.id}_${lessonCopy.type}` === currentCoursesLesson) lessonCopy.bookmark = true
                  unit.ls.push(lessonCopy)
                  break
                } else {
                  lesson.type = 'normal'
                  if (`${lesson.id}_${lesson.type}` === currentCoursesLesson) lesson.bookmark = true
                }
              }

              unit.lsIndex = index
              index ++
            } else {
              unit.ls[0].type = 'testout'
              unit.free = 1
            }

            // 所有testout、所有testout后面的一个单元为免费
            if (lastUnit && lastUnit.ls[0].type === 'testout') {
              unit.free = 1
            }

            // 英语课程第一单元前两课免费，其他课程第一单元免费
            if (unit.lsIndex === 1) {
              unit.ls[0].free = 1
              unit.ls[1].free = 1
            }
          }
          
          this.unitsData = unitsData
          this.$store.commit('set_coursesUnitsData', unitsData)
        } else {
          this.$store.commit('set_userLanguage', transList[0])
        }
        this.loading = false
      },
      startLessonHandle(lesson, lessonIndex, unitID, isFree, e) {
        if (!lesson.lock || constants.testMode) {
          if (isFree || this.isMember || constants.testMode) {
            lesson.unitID = unitID
            lesson.index = lessonIndex + 1
            if (lesson.type === 'dialoguePractice') {
              this.$store.commit('set_coursesDialogueInfo', lesson)
              this.$router.push('/courses/dialogue')
            } else if (lesson.type === 'dialogueSpeaking') {
              this.$store.commit('set_coursesDialogueInfo', lesson)
              if (lesson.redo) {
                this.maskShow = true
              } else {
                this.$router.push('/courses/speaking/follow')
              }
            } else {
              this.$store.commit('set_coursesLessonInfo', lesson)
              this.$router.push('/courses/lesson')
            }
          } else {
            this.$router.push('/pricing')
          }
        } else {
          if (lesson.type === 'testout') {
            this.$message({
              message: this.$t('course.please_complete_the_previous_test_out'),
              offset: e.target.getBoundingClientRect().top
            })
          } else {
            this.$message({
              message: this.$t('main.please_complete_previous_lesson'),
              offset: e.target.getBoundingClientRect().top
            })
          }
        }
      },
      startSpeakingHandle(type) {
        if (type === 'follow') {
          this.$router.push('/courses/speaking/follow')
        } else if (type === 'roleplay') {
          this.$router.push('/courses/speaking/roleplay')
        }
      },
      async startGrammarTipsHandle(unit, e) {
        if (!unit.lock) {
          if (unit.lsIndex === 1 || unit.free || this.isMember) {
            let grammarTipsData = await getGrammarTipsData(this.userLanguage, this.learnLanguage, unit.lsID)
            this.$store.commit('set_coursesGrammarInfo', grammarTipsData)
            this.$router.push('/grammarTips')
          } else {
            this.$router.push('/pricing')
          }
        } else {
          this.$message({
            message: this.$t('course.please_complete_previous_units_first'),
            offset: e.target.getBoundingClientRect().top - 48
          })
        }
      },
      startAlphabetHandle() {
        this.$router.push('/alphabet')
      },
      anchorHandle() {
        let unlockLessons = document.querySelectorAll('.lesson:not(.lock)')
        let lastUnlockLesson = unlockLessons[unlockLessons.length - 1]
        let top = lastUnlockLesson.offsetTop
        top += lastUnlockLesson.offsetParent.offsetTop
        window.scrollTo({
          top: top - 90,
          behavior: 'smooth'
        })
      },
      isInViewPort() {
        let el = document.querySelector('.bookmark')?.parentElement
        if (el) {
          const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight 
          const offsetTop = el.offsetTop
          const scrollTop = document.documentElement.scrollTop
          const top = offsetTop - scrollTop
          if (top < viewPortHeight && top > 80) {
            this.anchorShow = false
          } else {
            this.anchorShow = true
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @use 'sass:list';
  
  $textDeepColor: #151E34;
  $textLightColor: #787778;
  $colorGroup_1: (
    bgColor: #E5F5FC,
    deepColor: #4981B3,
    lightColor: #97B4CD,
    hoverColor: #F1FBFF,
    crownBgColor: #B8D8FF,
    crownColor: #3C7BCB
  );
  $colorGroup_2: (
    bgColor: #E8F7F9,
    deepColor: #639EA8,
    lightColor: #A6C0C4,
    hoverColor: #F4FFFB,
    crownBgColor: #B0E1EA,
    crownColor: #2D8793
  );
  $colorGroup_3: (
    bgColor: #EDF1FA,
    deepColor: #8E70B4,
    lightColor: #BCB4C6,
    hoverColor: #FAF9FF,
    crownBgColor: #DAC7F0,
    crownColor: #835BB3
  );
  $colorGroups: ($colorGroup_1 $colorGroup_2 $colorGroup_3);

  .courses {
    background: #F3F4F8;
    min-height: 100vh;

    .lockIcon { mask-image: url('~@/assets/images/courses/unit/lockIcon.png'); }
    .startIcon { mask-image: url('~@/assets/images/courses/unit/startIcon.png'); }
    .redoIcon { mask-image: url('~@/assets/images/courses/unit/redoIcon.png'); }
    .grammarTipsIcon { mask-image: url('~@/assets/images/courses/unit/grammarTipsIcon.png'); cursor: pointer; }
    .lessonIcon { mask-image: url('~@/assets/images/courses/unit/lessonIcon.png'); }
    .dialogueWarmUpIcon { mask-image: url('~@/assets/images/courses/unit/dialogueWarmUpIcon.png'); }
    .dialoguePracticeIcon { mask-image: url('~@/assets/images/courses/unit/dialoguePracticeIcon.png'); }
    .dialogueSpeakingIcon { mask-image: url('~@/assets/images/courses/unit/dialogueSpeakingIcon.png'); }
    .crownIcon { mask-image: url('~@/assets/images/courses/unit/crown.png'); }
    .testout { background-image: url('~@/assets/images/courses/unit/testoutBg.png'); }
    .testout { &.lock { background-image: url('~@/assets/images/courses/unit/testoutLockBg.png'); } }

    .contentWrap {
      display: flex;
      justify-content: space-between;
      margin: 80px auto 0;
      padding: 14px 0;
      width: 1000px;
      .testout {
        margin-bottom: 26px;
        width: 399px;
        height: 88px;
        line-height: 88px;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
        background-size: cover;
        cursor: pointer;
        &.lock {
          cursor: default;
        }
      }
      .joinPremium {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 26px;
        padding: 14px 0;
        width: 400px;
        background-image: linear-gradient(180deg, #FFFFFF 50%, #E7F7FF 100%);
        border: 1px solid #558ABF;
        border-radius: 18px;
        color: #000000; 
        box-sizing: border-box;
        cursor: pointer;
        img {
          width: 36px;
        }
        .title {
          margin-top: 10px;
          font-weight: 700;
          font-size: 16px;
        }
        .subtitle {
          font-weight: 400;
          font-size: 14px;
        }
        .buyBtn {
          margin-top: 16px;
          width: 240px;
          height: 38px;
          line-height: 38px;
          border-radius: 38px;
          background-image: linear-gradient(270deg, #0070B2 0%, #004E94 100%);
          box-shadow: 0 -4px 24px 0 #cecece40;
          font-weight: 700;
          font-size: 14px;
          color: #FFFFFF;
          text-align: center;
        }
      }
      .unitsWrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 60px 100px 30px;
        width: 680px;
        color: $textDeepColor;
        background: #FFFFFF;
        border-radius: 12px;
        .anchor {
          position: fixed;
          left: calc(50vw - 500px + 600px);
          bottom: 40px;
          width: 57px;
          cursor: pointer;
          z-index: 1000;
        }
        .unitWrap {
          position: relative;
          margin-bottom: 26px;
          padding: 20px 40px;
          width: 100%;
          border-radius: 18px;
          overflow: hidden;
          box-sizing: border-box;
          @for $i from 1 through 3 {
            &.colorGroup_#{$i} {
              background: map-get(list.nth($colorGroups, $i), bgColor);
              .unitIndex {
                background: map-get(list.nth($colorGroups, $i), deepColor);
              }
              .icon {
                background: map-get(list.nth($colorGroups, $i), deepColor);
              }
              .crownIconWrap {
                background: map-get(list.nth($colorGroups, $i), crownBgColor);
                .crownIcon {
                  background: map-get(list.nth($colorGroups, $i), crownColor);
                }
              }
              .lesson {
                border: 1px solid map-get(list.nth($colorGroups, $i), deepColor);
                &:hover:not(.lock) {
                  background: map-get(list.nth($colorGroups, $i), hoverColor);;
                }
              }

              &.lock, .lock {
                color: $textLightColor;
                cursor: default;
                .unitIndex {
                  background: map-get(list.nth($colorGroups, $i), lightColor);
                }
                .icon {
                  background: map-get(list.nth($colorGroups, $i), lightColor);
                }
                &.lesson {
                  border: 1px solid map-get(list.nth($colorGroups, $i), lightColor);
                }
                .grammarTipsIcon {
                  cursor: default;
                }
              }
            }
          }
          .icon {
            width: 24px;
            height: 24px;
            mask-size: cover;
          }
          .unitHeader {
            padding: 0 35px 0 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .unitNameWrap {
              display: flex;
              align-items: center;
              .unitIndex {
                margin-right: 18px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                border-radius: 24px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
              }
              .unitName {
                font-weight: bold;
                font-size: 20px;
              }
            }
          }
          .lesson {
            position: relative;
            margin-top: 16px;
            padding: 10px 35px 10px 16px;
            width: 100%;
            min-height: 60px;
            background: #FFFFFF;
            border-radius: 12px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .crownIconWrap {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 0;
              width: 34px;
              height: 18px;
              border-radius: 0 11px 0 11px;
              opacity: 0.6;
              .crownIcon {
                width: 14.4px;
                height: 12px;
                mask-size: cover;
              }
            }
            .bookmark {
              position: absolute;
              top: -3px;
              left: 36px;
              width: 18px;
            }
            .lessonTextWrap {
              width: 80%;
              .lessonIndexText {
                font-weight: 500;
                font-size: 14px;
              }
              .lessonDsText {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }
      .mask {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 1001;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background: #00000045;
        .dialogueSpeakingWindow {
          padding: 60px;
          position: relative;
          background: #FFFFFF;
          border-radius: 10px;
          .closeImg {
            position: absolute;
            top: 14px;
            right: 14px;
            width: 14px;
            cursor: pointer;
          }
          .windowContent {
            .followBtn, .roleplayBtn {
              display: flex;
              align-items: center;
              padding: 20px 40px;
              min-width: 340px;
              font-weight: 700;
              font-size: 16px;
              color: #303030;
              background: #FFFFFF;
              border-radius: 12px;
              box-sizing: border-box;
              cursor: pointer;
              transition: all 0.3s;
              img {
                margin-right: 12px;
                width: 26px;
              }
            }
            .followBtn {
              border: 1px solid #6091C3;
              &:hover {
                background: #EBF2FF;
              }
            }
            .roleplayBtn {
              margin-top: 24px;
              border: 1px solid #9587D6;
              &:hover {
                background: #FCF7FF;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .courses {
      .contentWrap {
        flex-direction: column;
        width: 100%;
        padding: 0;
        .unitsWrap {
          padding: 10px;
          width: 100%;
          border-radius: 0;
          .unitWrap {
            padding: 20px;
          }
          .joinPremium {
            width: 100%;
          }
          .testout {
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
</style>