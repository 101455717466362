<template>
  <div class="sidebar">
    <router-link v-show="!isMember" class="premiumCountdown" to="/pricing">
      <img class="premiumCountdownBg" src="@/assets/images/sidebar/premiumCountdown.png" alt="premiumCountdown">
      <div class="offText">{{$t('buy.50%_off')}}</div>
      <div class="countdown">
        <div class="num hour" ref="hour">00</div>
        <div class="colon">:</div>
        <div class="num minute" ref="minute">00</div>
        <div class="colon">:</div>
        <div class="num second" ref="second">00</div>
      </div>
    </router-link>
    <slot></slot>
    <div class="sidebarItem">
      <img class="itemIcon" src="@/assets/images/sidebar/daystreak.png" alt="daystreakIcon">
      <div class="itemNum">{{daystreak}}</div>
      <div class="itemText">{{$t('main.day_streak')}}</div>
    </div>
    <div class="sidebarItem">
      <img class="itemIcon" src="@/assets/images/sidebar/xp.png" alt="xpIcon">
      <div class="itemNum">{{xp}}</div>
      <div class="itemText">{{$t('main.experience')}}</div>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'

  export default {
    data() {
      return {
        daystreak: 1,
        xp: 0,
        timer: null
      }
    },
    computed: {
      ...Vuex.mapGetters(['historyData', 'userInfo', 'userLanguage', 'isMember', 'createTime'])
    },
    mounted() {
      this.init()
      this.countdown()
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
    watch: {
      historyData: {
        handler(historyData) {
          if (historyData) {
            let dateArr = []
            let xp = 0
            let dayRecordArr = historyData.split(';').filter(item => item !== '')
  
            for (let dayRecord of dayRecordArr) {
              let temp = dayRecord.split(':')
              let date = temp[0]
              dateArr.push(date)
  
              let earnXP = temp[1].split('_')[1]
              xp += Number(earnXP)
            }
  
            this.xp = xp
            this.daystreak = this.getDaystreak(dateArr.sort((a, b) => b - a))
          }
        },
        immediate: true
      }
    },
    methods: {
      init() {
        this.timer = setInterval(() => {
          this.countdown()
        }, 1000)
      },
      countdown() {
        let now = Date.parse(new Date())
        let diff = (now - this.createTime) / 1000
        let hour, minute, second, countdownSecond

        const oneDay = 24 * 3600
        const twoDay = 48 * 3600

        if (diff <= oneDay) {
          countdownSecond = oneDay - diff
        } else {
          countdownSecond = twoDay - ((diff - oneDay) % twoDay)
        }

        hour = this.twoDigits(Math.floor(countdownSecond / 3600))
        minute = this.twoDigits(Math.floor((countdownSecond / 60) % 60))
        second = this.twoDigits(Math.floor(countdownSecond % 60))
        this.$refs.hour.innerHTML = hour
        this.$refs.minute.innerHTML = minute
        this.$refs.second.innerHTML = second
      },
      twoDigits(val) {
        if (val.toString().length <= 1) {
          return '0' + val.toString()
        }
        return val.toString()
      },
      getDaystreak(dateArr) {
        let oneday = 24 * 60 * 60 * 1000
        let daystreak = 1
        
        for (let i = 0; i < dateArr.length; i ++) {
          let currentDate = dateArr[i]
          let preDate = dateArr[i + 1]

          if (preDate) {
            let formatCurrentDate = currentDate.slice(0, 4) + "-" + currentDate.slice(4, 6) + "-" + currentDate.slice(6, 8)
            let formatPreDate = preDate.slice(0, 4) + "-" + preDate.slice(4, 6) + "-" + preDate.slice(6, 8)
            let timestampCurrentDate = Date.parse(new Date(formatCurrentDate))
            let timestampPreDate = Date.parse(new Date(formatPreDate))

            if ((timestampCurrentDate - timestampPreDate) === oneday) {
              daystreak ++
            } else {
              return daystreak
            }
          }
        }

        return daystreak
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sidebar {
    width: 290px;
    .premiumCountdown {
      margin: 0 auto 20px;
      width: 279px;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 13px;
      cursor: pointer;
      &Bg {
        width: 100%;
      }
      .offText {
        position: absolute;
        left: 114px;
        color: #FFFFFF;
      }
      .countdown {
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #060606;
        .colon {
          margin: 0 3px;
        }
        .num {
          min-width: 18px;
        }
      }
    }
    .sidebarItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      width: 100%;
      height: 264px;
      background: #FFFFFF;
      border-radius: 12px;
      &.alphabet {
        cursor: pointer;
      }
      .itemIcon {
        width: 63px;
      }
      .itemNum {
        margin-top: 8px;
        font-weight: 500;
        font-size: 30px;
        color: #000000;
      }
      .itemText {
        margin-top: 5px;
        font-weight: 400;
        font-size: 16px;
        color: #595959;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sidebar {
      margin-top: 20px;
      width: 100%;
    }
  }
</style>